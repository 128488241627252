import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LayoutStyles from '../Components/Layout/Styles/LayoutStyles';
import NotificationList from '../Components/Organisations/Shared/NotificationList';
import AuthActions from '../Redux/AuthRedux';

const useStyles = makeStyles(theme => ({
  layout: { ...LayoutStyles.centered600Layout, justifyContent: 'center' },
  paper: LayoutStyles.centered600Paper,
  progress: { margin: theme.spacing(4) },
}));

const Notifications = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.auth.currentUser);
  const isFetching = useSelector(state => state.auth.isFetching);
  const notifications = useSelector(state => state.auth.notifications);

  const notifFetch =
    !isFetching &&
    notifications &&
    (notifications.length === 0 || notifications[0].helper_id === currentUser.id);

  React.useEffect(() => {
    dispatch(AuthActions.getHelperNotificationsRequest(currentUser.id));
  }, []);

  return (
    <Grid container className={classes.layout}>
      {!notifFetch && <CircularProgress size={36} className={classes.progress} />}
      {notifFetch && (
        <Paper className={classes.paper}>
          <NotificationList notifications={notifications} />
        </Paper>
      )}
    </Grid>
  );
};

export default Notifications;
